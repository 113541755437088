<template>
    <div class="col-xl-6 col-lg-12">        
        <div class="card info-card mb-2" @click="onOpenPack(pack.id)">
            <div class="card-body">
                <h5 class="card-title">
                    <div class="text-muted small pt-2 ps-1 float-end">                            
                        <span v-if="pack.checksComplete" class="badge rounded-pill bg-success text-light"><font-awesome-icon icon="fa-solid fa-check" /> Completed</span>
                        <div v-else>
                            <div v-if="pack.checksMissingCount ?? 0 > 0">
                                <span class="badge rounded-pill bg-danger text-light m-1">Missing: {{ pack.checksMissingCount }}</span>
                            </div>
                            <div v-if="pack.checksActiveCount ?? 0 > 0">
                                <span class="badge rounded-pill bg-primary text-light m-1">Active: {{ pack.checksActiveCount }}</span>
                            </div>                            
                        </div>
                    </div>
                    <font-awesome-icon icon="fa-regular fa-clipboard-check" />
                    <span class="ms-2">{{ pack.name }}</span>
                </h5>

                <div class="d-flex align-items-center">
                    <div class="ps-3">
                        <div>{{ pack.description }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts" name="PackDisplay">

    import { useRouter } from "vue-router";
    import type { PackDto } from '@/HttpClient';

    const router = useRouter();

    interface Props {
        pack: PackDto,
    }
    const props = defineProps<Props>()

    function onOpenPack(packId: number | undefined) {
        router.push({name : 'packView', params : { id : packId} });        
    }

</script>

<style scoped>
    .card-icon-background {
        background-color: whitesmoke;
        min-height: 100px;
    }

    .increase-size {
        font-size: 1.5rem;
    }

    .task-button {
        min-width: 100px;
    }
</style>