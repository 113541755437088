<template>

    <div class="pagetitle">
        <h1>
            <font-awesome-icon icon="fa-regular fa-shop" />
            {{storeNameAndNumber}}
        </h1>
        <nav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Home</router-link></li>
                <li class="breadcrumb-item active">Packs</li>
            </ol>
        </nav>
    </div>

    <ServerErrorDisplay :serverError="currentStoreServerError"></ServerErrorDisplay>

    <div v-show="storeKnown">
        <section class="section dashboard">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row">
                        <ServerErrorDisplay :serverError="loadingPackServerError"></ServerErrorDisplay>
                        <PackDisplay v-for="(pack, index) in processedPacks" :key="index" :pack="pack" />
                    </div>
                </div>

            </div>
        </section>
    </div>
    <!--Push permissions component starts here-->
    <PushPermissions v-if="!isAreaManager" :ready-to-request="storeKnown"></PushPermissions>
     <!--Push permissions component ends here-->
    <div v-show="!storeKnown">

        <div class="row">
            <div class="card info-card">

                <div class="card-body">
                    <div>
                        <h6 class="display-6">Please select the store you are in from the list below</h6>
                    </div>
                    <div v-show="showSearchingForStores" class="text-center">
                        <font-awesome-icon icon="fa-solid fa-cog" size="2x" class="me-1 fa-spin text-body-secondary" />
                        <span class="text-body-secondary fw-semibold fst-italic">Please wait while we check Nexus for possible stores</span>
                        <font-awesome-icon icon="fa-solid fa-cog" size="2x" class="ms-1 fa-spin fa-spin-reverse text-body-secondary" />
                    </div>
                    <div v-show="storeListFound">
                        <div>
                            <div v-for="(store, index) in possibleStoreList" :key="index" class="row store-option align-items-center">
                                <div class="col-6 text-start lead">{{store.storeId}} {{store.storeName}}</div>
                                <div class="col-6 text-end">
                                    <button type="button" class="btn btn-primary" @click="onStoreSelected(store.storeId)">Select</button>
                                </div>
                            </div>
                        </div>
                        <div class="text-center"><small class="text-body-secondary">This list has been generated from Nexus, based on your job role, location, and any temporary transfers.</small></div>
                    </div>
                    <ServerErrorDisplay :serverError="loadingStoresServerError"></ServerErrorDisplay>
                    <div v-show="noStoresFound">
                        <h1>Nexus was unable to find any related stores.  You cannot use CheckFlow.</h1>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup lang="ts">

    import { ResponsePackApi, type SetCurrentStoreCommand, UserApi, type GetResponsePacksViewModel, StoreSelectorApi, type ApiStoreSelectorPutRequest, type GetPossibleStoresViewModel, type PossibleStoreDto, UserAccessApi, type CachedUserAccessDto } from '@/HttpClient';
    import type ProblemDetails from "@/config/ProblemDetails";

    import { ref, computed } from 'vue'
    import { ApiConfig } from '@/config/apiConfig';

    import PackDisplay from '@/components/PackDisplay.vue';
    import PushPermissions from '@/components/PushPermissions.vue';

    const api = new ResponsePackApi(new ApiConfig());

    const apiUser = new UserApi(new ApiConfig());
    const apiStoreSelector = new StoreSelectorApi(new ApiConfig());
    const apiUserAccess = new UserAccessApi(new ApiConfig());

    let packs = ref<GetResponsePacksViewModel>({});
    let possibleStores = ref<GetPossibleStoresViewModel>({});
    let cachedUserAccess = ref<CachedUserAccessDto>({});

    const currentStore = ref<number>(0);
    const currentStoreName = ref<string>("");
    const storeSearchComplete = ref<boolean>(false);
    const showSearchingForStores = ref<boolean>(false);

    let currentStoreServerError = ref<ProblemDetails>();
    let loadingStoresServerError = ref<ProblemDetails>();
    let loadingPackServerError = ref<ProblemDetails>();

    apiUser.apiUserCurrentStoreGet()
        .then((data) => {
            currentStore.value = data;

            if (currentStore.value != 0) {
                loadingPackServerError.value = undefined;
                apiUser.apiUserCurrentStoreInfoGet()
                    .then((data) => {
                        currentStoreName.value = data.storeName ?? "";
                    })

                api.apiResponsePackIdGet({ id: currentStore.value })
                .then((data) => { packs.value = data; })
                .catch((error) => loadingPackServerError.value = error);
            }
            else {  
                showSearchingForStores.value = true;
                apiUserAccess.apiUserAccessGetCachedGet()
                .then((data) => {
                    cachedUserAccess.value.stores = data.stores;
                    cachedUserAccess.value.areaManagerAccess = data.areaManagerAccess;
                    storeSearchComplete.value = true;
                    showSearchingForStores.value = false;
                })
                .catch((error) => loadingStoresServerError.value = error);
            }
        })
        .catch((error) => currentStoreServerError.value = error);

    const storeKnown = computed(() => {
        return currentStore.value > 0;
    });

    const noStoresFound = computed(() => {
        return cachedUserAccess.value.stores?.length == 0 && storeSearchComplete.value == true;
    });

    let possibleStoreList = computed(() => {
        return cachedUserAccess.value.stores;
    });

    let storeListFound = computed(() => {
        return true;
    });

    let processedPacks = computed(() => {
        return packs.value?.packs
    });

    let isAreaManager = computed(() => {
        return cachedUserAccess.value.areaManagerAccess  &&  cachedUserAccess.value?.areaManagerAccess.areaId != 0 
    }) 

    let storeNameAndNumber = computed(() => {

        let name = "";

        if (currentStore.value > 0) {
            name = `${currentStoreName.value} - ${currentStore.value}`;
        }
        else {
            name = "Waiting for store selection...";
        }
        return name;
    });

    async function onStoreSelected(value: number | undefined) {

        let command: SetCurrentStoreCommand = { storeId: value };

        let storeSelectorApi = new StoreSelectorApi(new ApiConfig);

        let request: ApiStoreSelectorPutRequest = { setCurrentStoreCommand: command }

        await storeSelectorApi.apiStoreSelectorPut(request);

        currentStore.value = value ?? 0;
        loadingPackServerError.value = undefined;
        apiUser.apiUserCurrentStoreInfoGet()
            .then((data) => {
                currentStoreName.value = data.storeName ?? "";
                api.apiResponsePackIdGet({ id: currentStore.value })
                .then((data) => { packs.value = data; })
                .catch((error) => loadingPackServerError.value = error);
            });
    }

    
</script>
<style lang="scss">
    .store-option {
        list-style-type: none;
        border-radius: 5px;
        border: 1px solid black;
        padding: 5px;
        margin-bottom: 5px;
        background-color: #f6f9ff;
        font-weight: bolder !important;
    }
</style>