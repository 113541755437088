<template>

    <div class="pagetitle">
        <h1>
            <font-awesome-icon icon="fa-regular fa-shop" />
            {{ signOffPackViewModel.storeName }} - {{ signOffPackViewModel.storeId}}
        </h1>
        <nav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><router-link :to="{ name: 'home' }">Home</router-link></li>
                <li class="breadcrumb-item active">Sign Offs</li>
            </ol>
        </nav>
    </div>

    <ServerErrorDisplay :serverError="loadSectionsServerError"></ServerErrorDisplay>

    <div class="accordion" id="packAccordion" style="box-shadow: 0px 0 30px rgba(1, 41, 112, 0.1);">
        <div class="accordion-item" v-for="(section, index) in signOffPackViewModel.packSections" :key="index">
            <h2 class="accordion-header" :id="`heading${index}`">
                <button class="accordion-button fw-bold collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="`#collapse${index}`" aria-expanded="false" :aria-controls="`collapse${index}`">
                    {{ section.sectionName }}<span class="badge rounded-pill bg-info text-dark ms-2">{{section.packs?.length}}</span>
                </button>
            </h2>
            <div :id="`collapse${index}`" class="accordion-collapse collapse" :aria-labelledby="`heading${index}`" data-bs-parent="#packAccordion">
                <div class="accordion-body p-0">
                    <div class="pack-check-list">
                        <div class="check-item-grid flo-pack-item" v-for="pack in section.packs" :key="pack.id">
                            <div class="description ps-3">
                                <div class="fw-bold"><font-awesome-icon icon="fa-regular fa-clipboard-check" /> {{ pack.packName }}</div>
                                <div class="fw-light"><font-awesome-icon icon="fal fa-calendar" class="me-1" />Week {{ formatRetailWeek(pack.retailWeek) }}</div>
                                <div v-if="pack.isFullySignedOff" class="mt-1">

                                    <div v-if="pack.missed">
                                        <b>Store:</b><span v-show="pack.missed" class="badge bg-danger ms-1">Missed</span>
                                    </div>
                                    <div v-else>
                                        <b>Store:</b>
                                        <div class="ms-2">
                                            {{ pack.storeSignOffName }} <br /> <span class="fst-italic signoff-date">{{ formatDate(pack.storeSignOffTime?.toString()) }}</span>
                                        </div>
                                    </div>

                                    <div class="mt-2">
                                        <b>Area Manager:</b>
                                        <div class="ms-2">
                                            {{ pack.areaManagerSignOffName }} <br /> <span class="fst-italic signoff-date">{{ formatDate(pack.areaManagerSignOffTime?.toString()) }}</span>
                                        </div>
                                    </div>

                                </div>
                                <div v-else>

                                </div>
                            </div>
                            <div class="icon check-action-icon" @click="onOpenSignOff(pack.id)" v-show="pack.allowSignOff">
                                <font-awesome-icon icon="fa-solid fa-chevron-right" size="2x" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script setup lang="ts">

    import { ref, onMounted } from 'vue'
    import { useRouter } from "vue-router";
    import { SignOffApi, type GetSignOffPacksViewModel } from '@/HttpClient';
    import type ProblemDetails from "@/config/ProblemDetails";
    import { ApiConfig } from '@/config/apiConfig';
    import { format } from 'date-fns';

    const formatDate = (date: string | Date | undefined) => {
        if (date === undefined) return '';
        return format(new Date(date), 'dd/MM/yyyy HH:mm');
    }


    const formatRetailWeek = (retailWeek: number | undefined): string => {
        if (retailWeek === undefined) return '';

        const retailYearNum = retailWeek.toString().slice(0, 4);
        const retailWeekNum = retailWeek.toString().slice(4);
        return `${retailWeekNum}, ${retailYearNum}`;
    };

    const api = new SignOffApi(new ApiConfig());
    let signOffPackViewModel = ref < GetSignOffPacksViewModel > ({});
    const router = useRouter();

    let loadSectionsServerError = ref <ProblemDetails>();

    function loadSections() {
        loadSectionsServerError.value = undefined;
        api.apiSignOffGet()
            .then((data) => {
                signOffPackViewModel.value = data;
            })
            .catch((error) => loadSectionsServerError.value = error);
    }

    function onOpenSignOff(packId: number | undefined) {
        router.push({ name: 'signoffview', params: { id: packId } })
    }

    onMounted(() => {
        loadSections();
    })

</script>

<style scoped>

    .signoff-date {
        font-size: 0.8em;
    }

</style>